<script>
    import GlobalVue from '@helper/Global.vue';
    import Gen from '@helper/Gen';

    export default {
        extends: GlobalVue,
        data() {
            return {
                navName: '',
                wyneType: {
                    name: ''
                },
                topVarietal: {
                    name: ''
                },
                countEvent: 0,
                countBlog: 0,
                countClient: 0,
                countWineries: 0,
                spirit: {
                    mpc_is_active: 'N',
                    name: 'Spirits'
                },
                subspirit: {
                    name: ''
                },
                other: {
                    mpc_is_active: 'N',
                    name: 'Others'
                },
                subother: {},
                sweetness: {},
                body: {},
                country: {},
                event: {},
                isSubProd: "",
                isProd: "",
            }
        },
        computed: {
            isLogin() {
                this.user = Gen.getStorage('fo_user');
                return this.user && Gen.userToken() ? true : false
            },
            ufullname() {
                return this.user && Gen.userToken() ? this.user.name : '-'
            },
            isNotDesktop(){
                return this.isMobile || this.isTablet ? true : false
            }
        },
        async mounted() {
            await this.ready();
            if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
            this.apiGetWineType()
            this.apiGetTopVarietal()
            this.apiGetCount()
            this.getSpiritMenu()
            this.getOtherMenu()
            this.apiGetSweet()
            this.apiGetBody()
            this.apiGetCountry()
            AOS.init();
            setTimeout(() => {
                $("#primary-menu a").click((e) => {
                    e.stopPropagation();
                    e.preventDefault();
                })
            }, 300)

            // $(document).ready(()=>{
            //     setTimeout(() => {
            //         $(".mega-menu .mega-menu-content").hide();
            //     }, 300);
            // })

            if (this.isNotDesktop) {
                SEMICOLON.widget.init()
                $(window).scroll(function () {
                    if ($(window).scrollTop() > 0) {
                        $("#header.transparent-header").addClass("bg-white");
                    } else {
                        $("#header.transparent-header").removeClass("bg-white");
                    }
                })
            }
        },
        watch: {
            $route() {}
        },
        methods: {
            changeLang(hl) {
                window.location.href = changeLangLink(hl);
            },
            changeLangLink(hl) {
                window.location = this.$route.path + "?hl=" + hl
            },
            modalSearch() {
                VModal.openModal("ModalSearch", {}, () => {})
            },
            apiGetWineType() {
                Gen.apirest("/wyne-type", {}, (err, resp) => {
                    if (err) console.log(err)
                    this.wyneType = resp.data
                })
            },
            apiGetTopVarietal() {
                Gen.apirest("/top-varietals", {}, (err, resp) => {
                    if (err) console.log(err)
                    this.topVarietal = resp.data
                })
            },
            apiGetSweet() {
                Gen.apirest("/dry-scale", {}, (err, resp) => {
                    if (err) console.log(err)
                    this.sweetness = resp.data
                })
            },
            apiGetBody() {
                Gen.apirest("/body-size", {}, (err, resp) => {
                    if (err) console.log(err)
                    this.body = resp.data
                })
            },
            apiGetCountry() {
                Gen.apirest("/country-menu", {}, (err, resp) => {
                    if (err) console.log(err)
                    this.country = resp.data
                })
            },
            choseType(v) {
                this.$router.push({
                    path: '/wine',
                    query: { type: v }
                })
            },
            sweetType(v) {
                this.$router.push({
                    path: '/wine',
                    query: { scale: v }
                })
            },
            bodyType(v) {
                this.$router.push({
                    path: '/wine',
                    query: { body: v }
                })
            },
            countryType(v) {
                this.$router.push({
                    path: '/wine',
                    query: { country: v.toString() }
                })
            },
            spiritsType(v) {
                this.$router.push({
                    path: '/spirits-liquer',
                    query: { type: v }
                })
            },
            otherType(v) {
                this.$router.push({
                    path: '/others',
                    query: { type: v }
                })
            },
            getSpiritMenu(v) {
                Gen.apirest("/spirit-menu", {}, (err, resp) => {
                    if (err) console.log(err)
                    this.spirit = resp.data
                    this.subspirit = resp.sub
                })
            },
            getOtherMenu(v) {
                Gen.apirest("/other-menu", {}, (err, resp) => {
                    if (err) console.log(err)
                    this.other = resp.data
                    this.subother = resp.sub
                    this.isSubProd = resp.isSubProd
                    this.isProd = resp.isProd
                })
            },
            choseVarietal(v) {
                this.$router.push({
                    path: '/wine',
                    query: { varietal: v }
                })
            },
            apiGetCount() {
                Gen.apirest("/count-data", {}, (err, resp) => {
                    if (err) console.log(err)
                    this.countEvent = resp.event
                    this.countBlog = resp.blog
                    this.countClient = resp.client
                    this.countWineries = resp.wineries
                })
            },
            showMenu() {
                if (!this.isNotDesktop) return
                $('#primary-menu, body, #primary-menu-trigger').toggleClass("show-menu");
                setTimeout(() => { SEMICOLON.header.menufunctions() }, 500)
            },
            showMegaMenu() {
                // console.log("show-mega-menu")
                // $('.mega-menu').addClass("d-block")
            }
        },
        watch: {
            $route() {
                $("#primary-menu-trigger").removeClass("show-menu")
                $("#primary-menu, body").removeClass("show-menu")
            }
        }
    };
</script>

<template>
    <header id="header" class="full-header dark">
        <div id="header-wrap">
            <div class="container clearfix">
                <div id="primary-menu-trigger" @click="showMenu">
                    <i class="trigger">
                        <img :src="assets('fo_images','menu-2.svg')" alt="menu" title="menu" />
                    </i>
                    <i class="icon-line-cross menu-close"></i>
                </div>
                <div id="logo">
                    <router-link :to="{name:'Home'}" class="standard-logo"
                        :data-dark-logo="assets('fo_images','logo_dimatique.png')">
                        <img :src="assets('fo_images','logo_dimatique.png')" alt="Dimatique Logo"
                            title="Dimatique Logo" />
                    </router-link>

                    <router-link :to="{name:'Home'}" class="retina-logo"
                        :data-dark-logo="assets('fo_images','logo_dimatique.png')">
                        <img :src="assets('fo_images','logo_dimatique.png')" alt="Dimatique Logo"
                            title="Dimatique Logo" />
                    </router-link>
                </div>
				<div id="top-cart" class="d-lg-none d-sm-none d-md-none">
					<a href="javascript:;" @click="modalSearch()"><i class="icon-line-search"></i></a>
				</div>
				<div id="top-account" class="d-lg-none" v-if="!isLogin">
                    <router-link  :to="{name:'Login'}"><i class="icon-line2-user"></i></router-link>
				</div>
				<div id="top-search" class="d-none d-md-block d-lg-block">
				    <a href="javascript:;" @click="modalSearch()" id="">
				        <i class="icon-line-search"></i>
				    </a>
				</div>
                <nav id="primary-menu">
                    <ul>
                        <li v-if="isLogin && isNotDesktop">
                            <router-link :to="{name:'Dashboard'}">
                                <div>Hi, {{ufullname}} <i class="icon-angle-down"></i></div>
                            </router-link>
                            <ul>
                                <li v-if="isNotDesktop">
                                    <router-link :to="{name:'Dashboard'}">
                                        <div>Dashboard</div>
                                    </router-link>
                                </li>
                                <!-- <li ><router-link :to="{name:'AccountSetting'}">
									<div>{{web.mn_account_setting}}</div>
								</router-link></li> -->
                                <li>
                                    <router-link :to="{name:'logout'}">
                                        <div>{{web.lbl_log_out}}</div>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="">
                                <div>{{ web.lbl_discover }}<i class="icon-angle-down"></i></div>
                            </a>
                            <ul>
                                <li>
                                    <router-link :to="{name:'About'}">
                                        <div>{{ web.mn_about }}</div>
                                    </router-link>
                                </li>
                                <li v-if="countClient>0">
                                    <router-link :to="{name:'Client'}">
                                        <div>{{ web.mn_client }}</div>
                                    </router-link>
                                </li>
                                <li v-if="countWineries>0">
                                    <router-link :to="{name:'WineriesList'}">
                                        <div>{{ web.mn_wineries }}</div>
                                    </router-link>
                                </li>
                                <li v-if="countEvent>0">
                                    <router-link :to="{name:'Event'}">
                                        <div>{{ web.mn_event }}</div>
                                    </router-link>
                                </li>
                                <li v-if="countBlog>0">
                                    <router-link :to="{name:'Blog'}">
                                        <div>{{ web.mn_blog }}</div>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name:'CustomerFavourite'}">
                                        <div>{{ web.mn_customer_favourite }}</div>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name:'Faq'}">
                                        <div>{{ web.mn_faq }}</div>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="mega-menu" id="myMegaMenu">
                            <router-link :to="{name:'Product'}">
                                <div @click="showMegaMenu">{{ web.lbl_wine }} <i class="icon-angle-down"></i></div>
                            </router-link>
                            <div class="mega-menu-content style-2 clearfix">
                                <ul class="mega-menu-column col_menu_20">
                                    <li class="mega-menu-title">
                                        <router-link :to="{name:'Product'}">
                                            <div>{{ web.lbl_wine_types }}</div>
                                        </router-link>
                                        <ul v-if="wyneType.length">
                                            <li v-for="(w,k) in wyneType" :key="k">
                                                <a href="javascript:;" @click="choseType(w.slug)">
                                                    <div>{{w.name}}</div>
                                                </a>
                                            </li>
                                        </ul>
                                        <i v-if="isNotDesktop" class="icon-angle-right"></i>
                                    </li>
                                </ul>
                                <ul class="mega-menu-column col_menu_20">
                                    <li class="mega-menu-title">
                                        <router-link :to="{name:'Product'}">
                                            <div>{{ web.lbl_top_varietal }}</div>
                                        </router-link>
                                        <ul v-if="topVarietal.length">
                                            <li v-for="(w2,k2) in topVarietal" :key="k2">
                                                <a href="javascript:;" @click="choseVarietal(w2['mpv_id'])">
                                                    <div>{{ w2['mpv_name_'+hl] }}</div>
                                                </a>
                                            </li>
                                        </ul>
                                        <i v-if="isNotDesktop" class="icon-angle-right"></i>
                                    </li>
                                </ul>
                                <ul class="mega-menu-column col_menu_20">
                                    <li class="mega-menu-title">
                                        <router-link :to="{name:'Product'}">
                                            <div>{{ web.lbl_sweetness }}</div>
                                        </router-link>
                                        <ul v-if="sweetness.length">
                                            <li v-for="(s,ks) in sweetness" :key="ks">
                                                <a href="javascript:;" @click="sweetType(s['mds_id'].toString())">
                                                    <div>{{s['mds_name_'+hl]}}</div>
                                                </a>
                                            </li>
                                        </ul>
                                        <i v-if="isNotDesktop" class="icon-angle-right"></i>
                                    </li>
                                </ul>
                                <ul class="mega-menu-column col_menu_20">
                                    <li class="mega-menu-title">
                                        <router-link :to="{name:'Product'}">
                                            <div>{{ web.lbl_texture_small }}</div>
                                        </router-link>
                                        <ul>
                                            <li v-for="(b,kb) in body" :key="kb">
                                                <a href="javascript:;" @click="bodyType(b['mbps_id'].toString())">
                                                    <div>{{b['mbps_name_'+hl]}}</div>
                                                </a>
                                            </li>
                                        </ul>
                                        <!-- <i v-if="isNotDesktop" class="icon-angle-right"></i> -->
                                    </li>
                                </ul>
                                <ul class="mega-menu-column col_menu_20">
                                    <li class="mega-menu-title">
                                        <router-link :to="{name:'Product'}">
                                            <div>{{ web.lbl_countries }}</div>
                                        </router-link>
                                        <ul>
                                            <li v-for="(c,kc) in country" :key="kc">
                                                <a href="javascript:;" @click="countryType(c['mc_id'])">
                                                    <div>{{c['mc_name_'+hl]}}</div>
                                                </a>
                                            </li>
                                            <div class="more_link_product">
                                                <router-link :to="{name:'Product'}">{{ web.lbl_see_all_wine }}<i
                                                        class="icon-line-arrow-right"></i></router-link>
                                            </div>
                                        </ul>
                                        <!-- <i v-if="isNotDesktop" class="icon-angle-right"></i> -->
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li v-if="spirit && spirit.mpc_is_active=='Y'">
                            <router-link :to="{name:'Product2'}">
                                <div>{{spirit.name}} <i class="icon-angle-down"></i></div>
                            </router-link>
                            <ul v-if="subspirit.length">
                                <li v-for="(w,k) in subspirit" :key="k">
                                    <a href="javascript:;" @click="spiritsType(w.slug)">
                                        <div>{{w.name}}</div>
                                    </a>
                                </li>
                                <li>
                                    <router-link :to="{name:'Product2'}">
                                        <div>{{web.lbl_see_all}} <i class="icon-line-arrow-right"></i></div>
                                    </router-link>
                                </li>
                            </ul>
                            <i v-if="isNotDesktop" class="icon-angle-right"></i>
                        </li>

                        <li class="mega-menu">
                            <router-link :to="{name:'Product3'}">
                                <div>{{web.mn_water}}</div>
                            </router-link>
                        </li>
                        <li v-if="other.mpc_is_active=='Y'">
                            <router-link :to="{name:'Product4'}">
                                <div>{{other.name}} <i v-if="subother" class="icon-angle-down"></i></div>
                            </router-link>
                            <ul v-if="subother">
                                <li v-for="(w,k) in subother" :key="k">
                                    <a href="javascript:;" @click="otherType(w.slug)">
                                        <div>{{w.name}}</div>
                                    </a>
                                </li>
                                <li>
                                    <router-link :to="{name:'Product4'}">
                                        <div>{{web.lbl_see_all}} <i class="icon-line-arrow-right"></i></div>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">
                                <div>{{hl=='id'?'BAHASA':'ENGLISH'}} <i class="icon-angle-down"></i></div>
                            </a>
                            <ul>
                                <li>
                                    <a href="javascript:;" @click="changeLangLink('id')">
                                        <div>Bahasa</div>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:;" @click="changeLangLink('en')">
                                        <div>English</div>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="d-none d-lg-inline" v-if="!isLogin">
                            <router-link :to="{name:'Login'}" class="pad_top13">
                                <div class="btn_cta_login">{{ web.lbl_sign_in }}</div>
                            </router-link>
                        </li>
                        <li v-if="isLogin && !isNotDesktop">
                            <router-link :to="{name:'Dashboard'}">
                                <div>Hi, {{ufullname}} <i class="icon-angle-down"></i></div>
                            </router-link>
                            <ul>
                                <li ><router-link :to="{name:'Dashboard'}">
									<div>Dashboard</div>
								</router-link></li>
                                <li>
                                    <router-link :to="{name:'logout'}">
                                        <div>{{web.lbl_log_out}}</div>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
				</nav>
			</div>
		</div>
	</header>
</template>